import { HotelCard } from '@/app/home/components/HotelCard';
import hotels from '@/assets/data/hotels';


export const AllHotels = () => {
	return (
		<section id='other-hotels' className='mb-10'>
			<h1  className='text-3xl text-gray-400 text-center uppercase p-4'>Our Sister Hotels</h1>
			<div className='grid grid-cols-1 ml-[4rem] items-center gap-4 px-4 md:grid-cols-3 md:gap-0.5 md:p-0'>
				{hotels
					.map((hotel, key) => (
						// <a href={hotel.link} target='_blank' rel="noreferrer">
							<HotelCard key={key} {...hotel} />
						// </a>
					))}
			</div>
		</section>
	);
};
