/* eslint-disable react/jsx-no-undef */ 
import React from 'react'

const DashNavbar= () => {
  return (
    <div className='w-screen p-8 shadow bg-white'>
      <h1 className='text-center text-success text-4xl uppercase'>Igitego Apart Dashboard</h1>
    </div>
  )
}

export default DashNavbar