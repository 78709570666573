import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { RoomType } from "@/app/room/types";
import { BookForm } from "@/app/room/components/BookForm";
import services from "@/assets/data/services";
import socials from "@/assets/data/socials";
import axios from "axios";
import { Link } from "react-router-dom";

export const RoomRoute = () => {
  const token = localStorage.getItem("token");
  const [room, setRoom] = useState<RoomType>();
  const [isLoading, setIsLoading] = useState(true);
  const [isBookingForm, setIsBookingForm] = useState(false);

  const { slug } = useParams();
  useEffect(() => {
    if (slug) {
      handleGetRoom();
    }
    // eslint-disable-next-line
  }, [slug]);

  const handleIsBooking = () => {
    setIsBookingForm(true);
  };
  const handleGetRoom = () => {
    setIsLoading(true);

    const fetchRooms = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_API_URL}/rooms/${slug}`
        );
        setRoom(response.data.roomDetails);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching rooms:", error);
      }
    };
    fetchRooms();

    setIsLoading(false);
  };

  return isLoading ? null : (
    <>
      {/* <div className="fixed left-0 top-1/2 z-10 space-y-5 rounded-br-sm rounded-tr-sm bg-white p-4 shadow-2xl">
        {socials.map((social, key) => (
          <a
            key={key}
            href={social.link}
            className="block"
            target="_blank"
            rel="noreferrer"
          >
            <img alt={social.link} src={social.icon} />
          </a>
        ))}
      </div> */}

      {/* <div className="z-90 fixed right-10 top-1/2 flex flex-col  space-y-5 rounded-br-sm rounded-tr-sm bg-white p-4 shadow-2xl">
        {room?.title}
        <p className="space-x-1 font-serif text-xl text-zinc-400">
          ${room?.price}
          <span className=" ml-2 font-thin">{room?.perUnitTime}</span>
        </p>
        <Link to={`/room/${slug}/books`}>
          <button className="flex items-center justify-center rounded-sm bg-[#b5651d] px-2 py-[0.8rem] text-base  font-light text-white ">
            Book now ${room?.price} / {room?.perUnitTime}
          </button>
        </Link>
        <p className="text-normal font-serif    text-zinc-400">
          You won't be charged
        </p>
      </div> */}

      {/* <section className="py-10 md:py-14">
        <div className="container">
          <div className="flex flex-col md:flex-row h-full gap-2 ">
            <div className="h-full w-full  md:h-[482px]  md:w-96 ">
              <img
                alt={room?.title}
                src={room?.perRoomImages[0]}
                className="h-40 w-full rounded-sm object-cover md:h-[482px]  md:w-96"
              />
            </div>

            <div
              className={`grid  grid-col gap-3 md:w-96  md:grid-cols-2 md:gap-6`}
            >
              {room?.perRoomImages.map((img, index) => (
                <img
                  key={index}
                  alt={room?.title}
                  src={img}
                  className="h-fit w-full rounded-sm object-cover md:h-56"
                />
              ))}
            </div>
          </div>

          <div className="mt-10 flex flex-col gap-12 md:flex-row">
            <div className="space-y-4 md:basis-[60%]">
              <div>
                <div className="flex items-center justify-between md:block">
                  <h2 className="text-2xl font-medium text-dark-blue">
                    {room?.title}
                  </h2>

                  <div className="flex items-end gap-1">
                    <p className="space-x-1 font-serif text-xl  text-zinc-400">
                      {room?.price}$
                    </p>

                    <p className="relative top-[-3px] text-sm font-medium text-slate-400">
                      /
                    </p>

                    <p className="relative top-[-3px] text-sm font-medium text-slate-400">
                      Day
                    </p>
                  </div>
                </div>
              </div>

              <div className="space-y-0 text-[15px] leading-7 text-slate-600">
                <h1 className="font-bold text-black">Description:</h1>
                <p className="mb-5">{room?.description}</p>

                <ul className="space-y-2">
                  <h1 className="font-bold text-black">Facilities:</h1>
                  {room?.amenities.map((value: any, key: any) => (
                    <li key={key}>
                      <span className="text-lg">✓</span> {value}
                    </li>
                  ))}
                </ul>
              </div>

              <div>
                <h2 className="text-xl	font-medium text-dark-blue">
                  Room Services
                </h2>

                <hr className="mt-3 border-slate-300" />
                <div className="mt-5 grid grid-cols-2 gap-3 md:mt-6 md:grid-cols-3 md:gap-5">
                  {services.map((service, key) => (
                    <div key={key} className="flex items-center gap-3">
                      {service.icon}

                      <p className="grow text-[15px] font-medium text-slate-600">
                        {service.label}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      
    </>
  );
};
