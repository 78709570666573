import { RouterProvider } from 'react-router-dom';
import { AppProvider } from '@/core/providers';
import Router from '@/Router';

import { Crisp } from 'crisp-sdk-web'
import { useEffect } from 'react';

const App = () => {
	useEffect(()=>{
		if(document.readyState==='complete'){
			Crisp.configure(process.env.MY_CRISP_WEBSITE_ID!)
		}
	  },[])
	  
	return (
		<AppProvider>
			<RouterProvider router={Router} />
		</AppProvider>
	);
};

export default App;
