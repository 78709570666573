import React, { useEffect, useState } from "react";
import { PiDotsThreeVerticalBold } from "react-icons/pi";
import DataTable from "react-data-table-component";
import axios from "axios";

export const Booking = () => {
  const token = localStorage.getItem("token");
  const [bookings, setBookings]: any = useState();
  
  useEffect(() => {
    const fetchBookings = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_API_URL}/bookings`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setBookings(response.data.bookings);
      } catch (error) {
        console.error("Error fetching bookings:", error);
      }
    };

    fetchBookings();
  }, []);
  const columns = [
    {
      name: "First Name",
      selector: (row: any) => row.firstName,
      sortable: true,
    },
    {
      name: "Second Name",
      selector: (row: any) => row.lastName,
      sortable: true,
    },
    {
      name: "Check in",
      selector: (row: any) => row.checkIn,
      sortable: true,
    },
    {
      name: "Check Out",
      selector: (row: any) => row.checkOut,
      sortable: true,
    },
    {
      name: "Total Price",
      selector: (row: any) => row.totalPrice,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row: any) => row.bookingStatus,
      sortable: true,
    },
    {
      name: "Guests",
      selector: (row: any) => row.guests,
      sortable: true,
    },
  ];

  const [records, setRecord] = useState(bookings);

  const [filterValue, setFilterValue] = useState("");

  function handleFilter(event: React.ChangeEvent<HTMLInputElement>) {
    setFilterValue(event.target.value.toLowerCase());
  }

  useEffect(() => {
    if (!filterValue) {
      setRecord(bookings);
    } else {
      const newData = bookings.filter((record: any) =>
        record.bookingStatus.toLowerCase().includes(filterValue)
      );
      setRecord(newData);
    }
  }, [filterValue, bookings]);
  return (
    <div>
      <div className="container mt-5">
        <div className="text-end">
          <input
            type="text"
            placeholder="search by status"
            value={filterValue}
            onChange={handleFilter}
          ></input>
        </div>
        <DataTable
          columns={columns}
          data={records}
          selectableRows
          fixedHeader
          pagination
        ></DataTable>
      </div>
    </div>
  );
};
