import { Contact } from '@/components/containers';
import { NavBar, Footer } from '@/components/elements';
import { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

export const AppLayout = () => {
	const location = useLocation();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [location]);

	return (
		<>
			<NavBar />

			<main>
				<Outlet />

				<Contact />
				{/* <Partner /> */}
			</main>

			<Footer />
		</>
	);
};
