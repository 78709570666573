export const allImages = [
    'hotel-img/1.jpg',
    'hotel-img/2.jpg',
    'hotel-img/3.jpg',
    'hotel-img/4.jpg',
    'hotel-img/5.jpg',
    'hotel-img/6.jpg',
    'hotel-img/7.jpg',
    'hotel-img/8.jpg',
    'hotel-img/9.jpg',
    'hotel-img/untitled-2.jpg',
    'hotel-img/untitled-3.jpg',
    'hotel-img/untitled-4.jpg',
    'hotel-img/untitled-7.jpg',
    'hotel-img/untitled-8.jpg',
    'hotel-img/untitled-9.jpg',
    'hotel-img/untitled-10.jpg',
    'hotel-img/untitled-11.jpg',
    'hotel-img/untitled-12.jpg',
    'hotel-img/untitled-13.jpg',
    'hotel-img/untitled-14.jpg',
    'hotel-img/untitled-15.jpg',
    'hotel-img/untitled-16.jpg',
    'hotel-img/untitled-17.jpg',
    'hotel-img/untitled-18.jpg',
    'hotel-img/untitled-20.jpg',
    'hotel-img/untitled-21.jpg',
    'hotel-img/untitled-22.jpg',
    'hotel-img/untitled-23.jpg',
    'hotel-img/untitled-24.jpg',
    'hotel-img/untitled-25.jpg',
    'hotel-img/untitled-26.jpg',
    'hotel-img/untitled-27.jpg',
    'hotel-img/untitled-28.jpg',
    'hotel-img/untitled-29.jpg',
    'hotel-img/untitled-31.jpg',
    'hotel-img/untitled-32.jpg',
    'hotel-img/untitled-33.jpg',
    'hotel-img/untitled-34.jpg',
    'hotel-img/untitled-35.jpg',
    'hotel-img/untitled-36.jpg',
    'hotel-img/untitled-37.jpg',
    'hotel-img/untitled-38.jpg',
    'hotel-img/untitled-42.jpg',
    'hotel-img/untitled-43.jpg',
    'hotel-img/untitled-44.jpg',
    'hotel-img/untitled-47.jpg',
    'hotel-img/untitled-48.jpg',
    'hotel-img/untitled-49.jpg',
    'hotel-img/untitled-50.jpg',
    'hotel-img/untitled-51.jpg',
    'hotel-img/untitled-52.jpg',
    'hotel-img/untitled-53.jpg',
    'hotel-img/untitled-54.jpg',
    'hotel-img/untitled-55.jpg',
    'hotel-img/untitled-56.jpg',
    'hotel-img/untitled-57.jpg',
    'hotel-img/untitled-58.jpg',
    'hotel-img/untitled-59.jpg',
    'hotel-img/untitled-60.jpg',
    'hotel-img/untitled-61.jpg',
    'hotel-img/untitled-62.jpg',
    'hotel-img/untitled-63.jpg',
    'hotel-img/untitled-64.jpg',
    'hotel-img/untitled-65.jpg',
    'hotel-img/untitled-66.jpg',
    'hotel-img/untitled-67.jpg',
    'hotel-img/untitled-69.jpg',
    'hotel-img/untitled-70.jpg',
    'hotel-img/untitled-71.jpg',
    'hotel-img/untitled-72.jpg',
    'hotel-img/untitled-74.jpg',
    'hotel-img/untitled-75.jpg',
    'hotel-img/untitled-76.jpg',
    'hotel-img/untitled-77.jpg',
    'hotel-img/untitled-78.jpg',
    'hotel-img/untitled-79.jpg',
    'hotel-img/untitled-80.jpg',
    'hotel-img/untitled-82.jpg',
    'hotel-img/untitled-83.jpg',
    'hotel-img/untitled-84.jpg',
    'hotel-img/untitled-85.jpg',
    'hotel-img/untitled-86.jpg',
    'hotel-img/untitled-87.jpg',
    'hotel-img/untitled-88.jpg',
    'hotel-img/untitled-89.jpg',
    'hotel-img/untitled-90.jpg',
    'hotel-img/untitled-91.jpg',
    'hotel-img/untitled-92.jpg',
    'hotel-img/untitled-121.jpg',
    'hotel-img/untitled-122.jpg',
    'hotel-img/untitled.jpg'
]