import React, { useEffect, useState } from "react";
import { PiDotsThreeVerticalBold } from "react-icons/pi";
import DataTable from "react-data-table-component";

const RoomTable = ({ rooms }: any) => {
  const columns = [
    {
      name: "Title",
      selector: (row: any) => row.title,
      sortable: true,
    },
    {
      name: "Description",
      selector: (row: any) => row.description,
      sortable: true,
    },
    {
      name: "Price",
      selector: (row: any) => row.price,
      sortable: true,
    },
    {
      name: "Date",
      selector: (row: any) => row.createdAt,
      sortable: true,
    },
  ];

  const [records, setRecord] = useState(rooms);

  const [filterValue, setFilterValue] = useState("");

  function handleFilter(event: React.ChangeEvent<HTMLInputElement>) {
    setFilterValue(event.target.value.toLowerCase());
  }

  useEffect(() => {
    if (!filterValue) {
      setRecord(rooms);
    } else {
      const newData = rooms.filter((record: any) =>
        record.title.toLowerCase().includes(filterValue)
      );
      setRecord(newData);
    }
  }, [filterValue, rooms]);

  return (
    <div className="container mt-5">
      <div className="text-end">
        <input type="text" placeholder="search by name" value={filterValue} onChange={handleFilter}></input>
      </div>
      <DataTable
        columns={columns}
        data={records}
        selectableRows
        fixedHeader
        pagination
      ></DataTable>
    </div>
  );
};

export default RoomTable;
